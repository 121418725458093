import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Entity } from '@app/app.model';
import { AppService } from '@app/app.service';
import { ActionsService } from '@app/_shared/actions/actions.service';
import { ErrorsService } from '@app/_shared/errors/errors.service';
import { Customer, CustomerInterface } from './customer.model';
import { CustomerService } from './customer.service';
import { DxDataGridComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { cloneDeep } from 'lodash';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import CustomStore from 'devextreme/data/custom_store';
import { CompanyService } from '@settings/company/company.service';
import { IsUndefinedOrNull, dxDataGridHeightAdjustment, displayZeroValueText, refreshElement, makePDIDropDownOptions, dxSelectOptions } from '@app/common_helper';
import { SharedService } from '@app/_shared/shared.service';
import { ProductService } from '@products/product/product.service';

@Component({
  selector: 'facnm-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [
    {
      provide: CustomerInterface,
      useFactory: (httpClient: HttpClient, errors$: ErrorsService): any => {
        return new CustomerService(httpClient, errors$);
      },
      deps: [HttpClient, ErrorsService]
    }
  ]
})
export class CustomerComponent implements OnInit {

  constructor(
    private customers$: CustomerInterface,
    private actions$: ActionsService,
    private errors$: ErrorsService,
    private app$: AppService,
    private shared$: SharedService,
    private company$: CompanyService,
    private products$: ProductService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  private dataGridUnloadPoint: DxDataGridComponent;
  @ViewChild('dataGridUnloadPoint', { static: false }) set unloadPointContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridUnloadPoint = content;
    }
  }

  private dataGridSpcificPrice: DxDataGridComponent;
  @ViewChild('dataGridSpcificPrice', { static: false }) set dataGridSPContent(content: DxDataGridComponent) {
    if (content) {
      this.dataGridSpcificPrice = content;
    }
  }
  @ViewChild('entityForm') entityForm: NgForm;
  private unsubscribe$ = new Subject<void>();
  VCollapse = false;
  GICollapse = false;
  ADCollapse = false;
  CICollapse = false;
  UPCollapse = false;
  OTCollapse = false;
  saveTypeProgress: boolean;
  modalError: string;
  gridData: ArrayStore;
  isAddForm = 0;
  collapseColor = 'green';
  entityName = 'Customers';
  entityNameNotification = "Customer";
  entityData: Entity<Customer>;
  newEntityData: Customer;
  newEntity: any;
  customDataSource: any;
  user: any;
  countries: any;
  specialPriceGroups: any;
  companies: any;
  languages: any;
  customerTypes: any;
  generalProductList: any;
  productList: any;
  productDropdown: any;
  billQuantityTypeList: any;
  nonPrintCompanies: any;
  autoEmailOptionsList: any;
  signatureSettingsOptions: any;
  productOptions: any;
  printOptions: any;
  strappingChartProfileDropdown: any;
  dippingTypes: any;
  paymentTerms: any;
  unloadPoint: any;
  isUnloadPointSelected: boolean;
  isIdenticalToCustomer: boolean;
  deleteWarning: boolean;
  lastDeliveryLocation: {};
  showProductModal: boolean;
  unloadPointProductName: any;
  showBillingCustomerModal: boolean;
  filterOptions = {
    CustomerNo: null,
    CustomerName: null,
    CustomerAddress: null,
    GridPagerOptions: { PageIndex: 0, PageSize: 20, TotalPages: null }
  };
  PageSize = 20;
  TotalCount = null;
  originalId: any;
  originalPos: any;
  showCustomerTypeModal = false;
  customerType = { Id: "", Name: "" }
  searchExprOption = ['Name', 'Code'];
  defaultSCPItem = { Id: null, ProfileId: "Select" };
  defaultDippingType = { id: null, name: "Default" };
  paymentTermsDef = { Id: null, Name: "None" };
  popoverVisibility: boolean[] = [];
  defaultBillQuantityType = {Id: null, Name: "None"};
  dxSelectOptions = new dxSelectOptions();
  ngOnInit(): void {
    this.actions();
    this.getCustomDataSource();
    this.app$.setScreenTitle(this.entityName);
    this.app$.loginUserInfo.subscribe((res: any) => {
      this.user = {};
      this.user.isStrappingChartEnabled = res.IsStrappingChartEnabled;
    });

    forkJoin({
      newEntity: this.customers$.getNewEntity(),
      countries: this.shared$.getCountryList(),
      companies: this.shared$.getCompanies(0),
      specialPriceGroups: this.customers$.getSpecialPriceGroupList(),
      languages: this.shared$.getLanguageList(),
      customerTypes: this.shared$.getCustomerTypeList(),
      productList: this.customers$.getProductList(0),
      productDropdown: this.customers$.getProductDropdown(),
      nonPrintCompanies: this.company$.getNonPrintCompanyList(0),
      billQuantityTypeList: this.customers$.getBillQuantityTypeList(),
      autoEmailOptionsList: this.customers$.getAutoEmailOptionsList(),
      signatureSettingsOptions: this.customers$.getSignatureSettingsOptionList(),
      productOptions: this.customers$.getProductOptions(),
      printOptions: this.customers$.getPrintOptions(),
      strappingChartProfileDropdown: this.customers$.getStrappingChartProfile(),
      dippingTypes: this.customers$.getDippingType(),

    }).subscribe(
      (res: any) => {
        this.newEntityData = res['newEntity'];
        this.countries = res['countries'];
        this.companies = res['companies'];
        this.specialPriceGroups = res['specialPriceGroups'];
        this.languages = res['languages'];
        this.customerTypes = res['customerTypes'];
        this.productList = res['productList'];
        this.generalProductList = cloneDeep(res['productList'])
        this.productDropdown = res['productDropdown'];
        this.nonPrintCompanies = res['nonPrintCompanies'];
        this.billQuantityTypeList = res['billQuantityTypeList'];
        this.billQuantityTypeList.splice(0,0,this.defaultBillQuantityType);
        this.autoEmailOptionsList = res['autoEmailOptionsList'];
        this.signatureSettingsOptions = res['signatureSettingsOptions'].filter((a: any) => a.name != 'None');
        this.productOptions = res['productOptions'].filter((a: any) => a.name != 'None');
        this.printOptions = res['printOptions'].filter((a: any) => a.name != 'None');
        this.strappingChartProfileDropdown = res['strappingChartProfileDropdown'];
        this.strappingChartProfileDropdown.splice(0, 0, this.defaultSCPItem);
        this.dippingTypes = res['dippingTypes'];
        this.dippingTypes.splice(0, 0, this.defaultDippingType);
      },
      (errorNetwork) => {
        this.errors$.handleServerErrors(errorNetwork);
      }
    );
  }

  actions() {

    this.actions$.EntityAdding.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.addNewEntity());
    this.actions$.EntityCreating.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(false));
    this.actions$.EntitySaving.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.create(true));
    this.actions$.EntityDeleteConfirmed.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.deleteConfirm());
    this.actions$.EntityResetting.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.reset());
    this.actions$.EntityCanceling.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.cancel());
    this.errors$.ServerErrorsReceived.pipe(takeUntil(this.unsubscribe$)).subscribe((errors) => {
      if (errors) {
        let entityForm = document.getElementById('entity-form');
        if (entityForm) {
          entityForm.scrollTop = 0;
        }
      }
    });
  }

  getCustomDataSource() {
    this.customDataSource = new CustomStore({
      key: ["Id", "Position"],
      load: (loadOptions) => {
        if (loadOptions != undefined) {
          this.filterOptions.CustomerName = null;
          this.filterOptions.CustomerAddress = null;
          this.filterOptions.CustomerNo = null;
          if (loadOptions.filter != undefined) {
            if (loadOptions.filter.length) {
              if (!Array.isArray(loadOptions.filter[0])) {
                let filter = loadOptions.filter;
                loadOptions.filter = [];
                loadOptions.filter.push(filter);
              }
            }
            for (var i = 0; i < loadOptions.filter.length; i++) {
              if (loadOptions.filter[i].length == 3) {
                if (loadOptions.filter[i][0] == "FullName") this.filterOptions.CustomerName = loadOptions.filter[i][2];
                else if (loadOptions.filter[i][0] == "Address") this.filterOptions.CustomerAddress = loadOptions.filter[i][2];
                else if (loadOptions.filter[i][0] == "Number") this.filterOptions.CustomerNo = loadOptions.filter[i][2];
              }
            }
          }
          this.filterOptions.GridPagerOptions.PageSize = (IsUndefinedOrNull(loadOptions.take) == true) ? this.PageSize : loadOptions.take;
          this.filterOptions.GridPagerOptions.PageIndex = ((IsUndefinedOrNull(loadOptions.take) && IsUndefinedOrNull(loadOptions.skip)) == true) ? 0 : (loadOptions.skip / loadOptions.take);
        }
        return this.customers$.getList(this.filterOptions)
          .toPromise()
          .then((response: any) => {
            this.app$.notifyScreenReady();
            return {
              data: response.CustomerListVM,
              totalCount: response.TotalCount,
            };
          })
          .catch((errorNetwork) => this.errors$.handleServerErrors(errorNetwork));
      }
    });
  }

  onContentReadyGrid(e) {
    this.dataGrid.noDataText = this.dataGrid.instance.totalCount() ? '' : "No Customers Found";
  }

  onCellPrepared(e: any) {
    if (e.rowType === "data" && e.column.dataField === "Address") {
      let addressLine2 = [];
      if (e.data.PostcodeString != null) addressLine2.push(e.data.PostcodeString);
      if (e.data.City != null) addressLine2.push(e.data.City);
      if (e.data.District != null) addressLine2.push(e.data.District);
      if (e.data.State != null) addressLine2.push(e.data.State);
      e.cellElement.innerHTML = e.data.Address + '<br>' + addressLine2.join(" ");
    } else if (e.rowType === "data" && e.column.dataField === "FullName") {
      e.cellElement.innerHTML = e.data.Name1 + '<br>' + e.data.Name2;
    }
  }

  private handleFormSubmit() {
    this.entityForm.form.markAsPristine();
    this.entityForm.form.markAsUntouched();
    this.entityForm.form.updateValueAndValidity();
  }

  create(isSave: boolean) {
    this.handleFormSubmit();
    this.entityForm.onSubmit(undefined);
    if (this.entityForm.form.invalid) {
      setTimeout(() => {
        this.app$.notifyScreenReady();
      })
      return;
    }

    var id = -1;
    this.entityData.value.UnloadPoints.forEach((unloadPoint: any) => {
      if (unloadPoint.CustomerId == null && unloadPoint.CustomerPos == null) {
        unloadPoint.CustomerId = this.entityData.value.Id;
        unloadPoint.CustomerPos = this.entityData.value.Position;
        unloadPoint.Id = id;
        id--;
      }
    });

    this.entityData.value.Latitude = this.entityData.value.Latitude == 0 ? null : this.entityData.value.Latitude;
    this.entityData.value.Longitude = this.entityData.value.Longitude == 0 ? null : this.entityData.value.Longitude;

    this.entityData.value.originalId = this.originalId;
    this.entityData.value.originalPos = this.originalPos;

    this.customers$.create(this.entityData).subscribe(
      (okCreate) => {
        this.app$.setScreenTitle(this.entityName);
        isSave ? this.actions$.notifySaved() : this.actions$.notifyCreated();
        this.deSelectEntity();
        this.refreshGrid();
        this.app$.notifyScreenReady();
      },
      (errorCreate) => {
        this.errors$.handleServerErrors(errorCreate);
      }
    );
  }

  private deleteConfirm() {
    let param = { Id: this.entityData.value.Id, Position: this.entityData.value.Position };
    this.customers$.delete(param).subscribe(
      (okDelete) => {
        this.cancel();
        this.refreshGrid();
        this.actions$.notifyDeleted();
        this.app$.notifyScreenReady();
      },
      (errorDelete) => {
        this.errors$.handleServerErrors(errorDelete);
      }
    );
  }

  private addNewEntity() {
    this.resetUnLoadPointObjects();
    this.app$.setScreenTitle('New ' + this.entityName);

    this.entityData = new Entity();
    this.entityData.value = cloneDeep(this.newEntityData);
    this.entityData.created = true;
    this.entityData.value.SpecialPriceProducts = [];
    this.entityData.value.UnloadPoints = [];
    this.entityData.value.DeliveryCustomers = [];
    this.changeDetectorRef.detectChanges();
    this.productList = cloneDeep(this.generalProductList);

    this.originalId = 0;
    this.originalPos = 0;
    this.isAddForm = 1;
    this.collapseColor = 'yellow';

  }

  private selectEntity(key: any) {
    this.resetUnLoadPointObjects();
    this.app$.notifyScreenBusy();
    this.isAddForm = 0;
    this.collapseColor = 'green';
    this.customers$.getEntity(key).subscribe((res: any) => {
      this.app$.notifyScreenReady();
      this.entityData = new Entity();
      this.entityData.key = key;
      this.entityData.value = cloneDeep(res);
      refreshElement(this,'customerTypeBillingOrDeliverLoading');
      this.changeDetectorRef.detectChanges();
      this.originalId = res.Id;
      this.originalPos = res.Position;
      if(this.entityData.value.AutoEmailOptions == 0){
        displayZeroValueText(this.autoEmailOptionsList,'autoEmailOptions','Id','Name');
      }
      if(this.entityData.value.TypeId == 0){
        displayZeroValueText(this.customerTypes,'customerTypeId','Id','Name');
      }
      if(this.entityData.value.CountryId == 0){
        displayZeroValueText(this.countries,'countryId','Id','Name');
      }
      if (!this.companies.find(o => o.Id == this.entityData.value.CompanyId)) {
        this.entityData.value.CompanyId = null;
        let error = {
          modelState: "",
          message: "Invalid Company is detected.",
          details: "",
          redirectToLogin: false,
        };
        this.errors$.handleServerErrors(error);
      }
      if (!this.nonPrintCompanies.find(o => o.Id == this.entityData.value.CompanyId)) {
        this.entityData.value.CompanyId = null;
      }

      this.getPaymentTermList(this.entityData.value.CompanyId);

      if (this.entityData.value.PriceGroupId != null && this.entityData.value.PriceGroupId != 0) {
        this.getProductList(this.entityData.value.PriceGroupId);
      }
      else {
        this.productList = cloneDeep(this.generalProductList);
      }
      this.refreshSpecialPriceGrid();
      this.clearSelectAndRefreshUnloadPoint();

    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  getPaymentTermList(companyId) {
    this.customers$.getPaymentTermList(companyId).subscribe((res: any) => {
      this.paymentTerms = res;
      this.paymentTerms.splice(0, 0, this.paymentTermsDef);
      refreshElement(this,'paymentTermsLoading');
      if(this.entityData.value.PaymentTermsId == 0){
        displayZeroValueText(this.paymentTerms,'paymentTermsId','Id','Name');
      }
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  onCompanyChange = function () {
    this.getPaymentTermList(this.entityData.value.CompanyId);
  };

  refreshGrid() {
    this.dataGrid.instance.refresh();
  }

  private deSelectEntity() {
    this.dataGrid.instance.deselectRows(this.entityData.key);
    this.entityData.key = undefined;
  }

  private reset() {
    this.entityData.restore();
    let selectedRow = this.dataGridUnloadPoint.instance.getSelectedRowKeys();
    if (selectedRow.length) {
      this.dataGridUnloadPoint.instance.clearSelection();
      this.dataGridUnloadPoint.instance.selectRows(selectedRow, false);
    }
    if (IsUndefinedOrNull(this.entityData.value.SpecialPriceProducts)) {
      this.entityData.value.SpecialPriceProducts = [];
    }
  }

  private cancel() {
    this.app$.setScreenTitle(this.entityName);
    this.deSelectEntity();
  }

  onInitialized(e: any) {
    this.resizeScreenContent();
  }

  onRowClick($clikedRow: { key: any; }) {
    this.app$.setScreenTitle(this.entityName);
    if (!this.entityData) {
      this.entityData = new Entity();
      this.entityData.key = undefined;
    }

    if (JSON.stringify(this.entityData.key) === JSON.stringify($clikedRow.key)) {
      this.deSelectEntity();
    }
    else {
      this.selectEntity($clikedRow.key);
    }
  }

  onCustomerTypeChange() {
    if (this.originalId != 0)
      this.entityData.value.Number = '';
  }

  onPriceGroupChange() {
    if (this.entityData.value.PriceGroupId != null && this.entityData.value.PriceGroupId != undefined && this.entityData.value.PriceGroupId > 0) {
      this.getProductList(this.entityData.value.PriceGroupId);
    } else {
      this.productList = cloneDeep(this.generalProductList);
    }
  }

  getProductList(priceGroupId) {
    this.customers$.getProductList(priceGroupId).subscribe((data) => {
      this.productList = data;
      this.refreshSpecialPriceGrid();
    }, (errorGetList) => this.errors$.handleServerErrors(errorGetList));
  }

  refreshSpecialPriceGrid() {
    if (this.dataGridSpcificPrice) {
      this.dataGridSpcificPrice.instance.refresh();
    }
  }

  unloadPointRowRemoving(event: any) {
    if (this.entityData && this.entityData.value.Position != 1 && this.entityData.value.UnloadPoints.length < 2) {
      event.cancel = true; // Cancel the deletion
      this.deleteWarning = true;
    }
}

  resetUnLoadPointObjects() {
    this.unloadPoint = {};
    this.lastDeliveryLocation = {};
    this.isUnloadPointSelected = false;
  }

  addNewUnloadPoint = function () {
    let unloadPoint = {};
    let id = 1;
    if (this.entityData && this.entityData.value.UnloadPoints?.length > 0) {
      let sortULP = this.entityData.value.UnloadPoints.sort((a, b) => b.Id - a.Id);
      id = sortULP[0].Id + 1;;
    }
    else {
      this.entityData.value.UnloadPoints = [];
    }
    unloadPoint['Id'] = id;
    this.entityData.value.UnloadPoints.push(unloadPoint);
    this.clearSelectAndRefreshUnloadPoint()
  }

  clearSelectAndRefreshUnloadPoint() {
    this.unloadPoint = {};
    if (this.dataGridUnloadPoint) {
      this.dataGridUnloadPoint.instance.deselectAll();
      this.dataGridUnloadPoint.instance.refresh();
    }
  }

  onContentReadyUnloadPoint(e) {
    this.dataGridUnloadPoint.noDataText = this.dataGridUnloadPoint.instance.totalCount() ? '' : "No Data";
  }

  onChangeUnloadPoint(selectedRowsInfo) {
    this.unloadPointProductName = '';
    if (selectedRowsInfo.selectedRowsData.length > 0 && !selectedRowsInfo.selectedRowsData[0].Changed) {
      let data = selectedRowsInfo.selectedRowsData[0];
      this.unloadPoint = this.entityData.value.UnloadPoints.find(a => a.Id == data.Id);
      if(this.unloadPoint.DipRequired == 0){
        displayZeroValueText(this.dippingTypes,'dipRequired','Id','Name');
      }
      
      this.isUnloadPointSelected = true;
      this.isIdenticalToCustomer = this.unloadPoint.Name1 == null;
      if (data.CustomerPos != data.LastDeliveryCustomerPos && data.LastDeliveryCustomerPos > 0) {
        this.customers$.getLastDeliveryLocationById(data.CustomerId, data.LastDeliveryCustomerPos).subscribe((res: any) => {
          this.lastDeliveryLocation = res;
        }, (err) => this.errors$.handleServerErrors(err));
      }
      else {
        this.lastDeliveryLocation = {};
      }
      if (this.unloadPoint.ProductId) {
        this.products$.getEntity(this.unloadPoint.ProductId).subscribe((res: any) => {
          this.unloadPointProductName = res.ShortProductName;
        }, (err) => this.errors$.handleServerErrors(err));
      }
    }
    else if (selectedRowsInfo.selectedRowsData.length > 0 && selectedRowsInfo.selectedRowsData[0].Changed) {
      this.unloadPoint = selectedRowsInfo.selectedRowsData[0];
      this.isUnloadPointSelected = true;
      this.isIdenticalToCustomer = this.unloadPoint.Name1 == null;
    }
    else {
      this.unloadPoint = {};
      this.isUnloadPointSelected = false;
    }
  }

  viewProductModal() {
    this.showProductModal = true;
  }

  productSelectionOk(data: any) {
    this.showProductModal = false;
    if (data.length > 0) {
      let selectedRowData = data[0];
      this.unloadPoint.ProductId = selectedRowData.Id;
      this.unloadPointProductName = selectedRowData.ShortProductName;
    }
  }

  productSelectionCancel() {
    this.showProductModal = false;
  }

  addCustomerType() {
    this.modalError = "";
    this.saveTypeProgress = true;
    this.shared$.saveCustomerType(this.customerType).subscribe(
      (okCreate) => {
        this.saveTypeProgress = false;
        this.actions$.notifyEntityCustomAction({ title: 'Success', message: 'Product Type created successfully' });
        this.closeCustomerType();
        this.shared$.getCustomerTypeList().subscribe((res) => {
          this.customerTypes = res;
          refreshElement(this,'customerTypeLoading');
        }, (err) => {
          this.errors$.handleServerErrors(err);
        })
      },
      (errorCreate) => {
        this.saveTypeProgress = false;
        this.modalError = errorCreate.message;
      }
    );
  }

  closeCustomerType() {
    this.customerType.Id = '';
    this.customerType.Name = '';
    this.showCustomerTypeModal = false;
    this.saveTypeProgress = false;
    this.modalError = '';
  }

  viewBillingCustomerModal() {
    this.showBillingCustomerModal = true;
  }

  billingCustomerSelectionOk(data: any) {
    this.showBillingCustomerModal = false;
    if (data.length > 0) {
      let selectedRowData = data[0];
      this.entityData.value.Id = selectedRowData.Id;
      this.entityData.value.DefaultCustomerName = selectedRowData.Name1;
    }
  }

  billingCustomerSelectionCancel() {
    this.showBillingCustomerModal = false;
  }

  languageLabel(item: any): string {
    return item ? item.Name + ' - ' + item.Code : '';
  }

  onRowInsertedSpecificPrice(event: any) {
    event.data.Changed = true;
    event.data.CustomerId = this.entityData.value.Id != undefined ? this.entityData.value.Id : 0;
    event.data.CustomerPosition = this.entityData.value.Position;
  }

  onContentReadySpecificPrice(e: any) {
    this.dataGridSpcificPrice.noDataText = this.dataGridSpcificPrice.instance.totalCount() ? '' : "No Data";
  }

  togglePopover(rowIndex: number) {
    this.popoverVisibility[rowIndex] = !this.popoverVisibility[rowIndex];
  }

  getGroupSpecialPrices(selectedId) {
    let rows = [];
    let selProduct = this.productList.filter(a => a.Id == selectedId);
    selProduct = selProduct ? selProduct[0] : null;
    if (selProduct && selProduct.GroupSpecialPrices && selProduct.GroupSpecialPrices.length > 0) {
      selProduct.GroupSpecialPrices.forEach((a: any) => {
        let row = { min: a.MinimumQty + " " + selProduct.QuantityUnit, price: a.Price + "/" + selProduct.PricePer + " " + selProduct.QuantityUnit };
        rows.push(row);
      });
    }
    return rows;
  }

  collapseClass(pannel) {
    return {
      'collapsible-header-icon-green-up': !pannel && !this.isAddForm,
      'collapsible-header-icon-green-down': pannel && !this.isAddForm,
      'collapsible-header-icon-yellow-up': !pannel && this.isAddForm,
      'collapsible-header-icon-yellow-down': pannel && this.isAddForm,
    }
  }

  strappingChartDisplayExpr(item) {
    return item ? (item.ProfileDescription ? `${item.ProfileId} - ${item.ProfileDescription}`: `${item.ProfileId}` ) :'';
  }

  hasError(control) {
    return this.entityForm?.submitted && this.entityForm.form.get(control)?.errors;
  }

  checkError(control, type) {
    return this.entityForm.form.get(control).errors[type];
  }

  onResize(e: any): void {
    this.resizeScreenContent();
  }

  formHeight: string;
  @ViewChild('screen', { static: true }) screenElement: ElementRef;
  private resizeScreenContent() {
    let h = this.screenElement.nativeElement.clientHeight;
    this.formHeight = h - 80 + 'px';
    this.dataGrid.instance.option('height', h - dxDataGridHeightAdjustment);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
